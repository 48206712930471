import React from 'react'
import { Link } from 'react-router-dom';
function Contact() {
  return (<section id="home" style={{ padding: "0 !important" }}>
    <div style={{ marginTop: "8.7%" }} />
    <main className="content">
      <section className="contact-sections contact-header container border-bottom text-center">
        <h1>Contact us</h1>
        {/* <div class="mini-container text-center">
   <p class="notice">
    All our sales offices are closed as a preventive measure against the spread of Corona virus. You can reach out to our executives who will be working from home, at <span><strong>080 4719 2020</strong></span> / <span><strong>080 7117 5337</strong></span>
  </p>
      </div> */}
      </section>
      <section className="contact-sections container office-contacts">
        <div className="row between">
          <div className="three columns center-on-mobile">
            <div className="support-box">
              <p className="text-14">Have a query?</p>
              <Link className="button" to="/form">
                Contact us
              </Link>
            </div>
          </div>
          <div className="five columns center-on-mobile">
            <h5>Support</h5>
            <p className="text-grey">
              Monday – Friday
              <br />
              10:00 AM - 5:00 PM
            </p>
            <p className="bottom-5 contact-phone-link">
              <span className="icon-phone" />
              <a href="tel:08437376889">0161-4072791 </a>
            </p>
          </div>
        </div>
        <br />
        <div className="row between">
          <div className="three columns center-on-mobile">
            <div className="support-box">
              <p className="text-14">Check your Investor complaint status now!</p>
              <Link to="/Complaint" className="button">
              Investor complaint status
              </Link>
            </div>
          </div>
        </div>
        <br />
      </section>
      <section className="contact-sections contact-map container">
        <br />
        <div className="card cardWrapper HOAddress">
          <div className="card-body">
            <div className="text-center cardHeader">
              Contact Mail for Grievances
            </div>
            <div className="row justify-content-center pt-3 text-center">
              <div className="col-12 col-md-6 col-lg-3 mb-4 px-2">
                <img src="./img/grievence_1.svg" alt="" className="wh70" />
                <div className="pb-2 font-weight-bolder">
                  For trading related grievances
                </div>
                <div className="size13 greenElement text-center">
                  <a href="mailto:wecare@ourbroking.com">wecare@ourbroking.com</a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4 px-2">
                <img src="./img/grievence_2.svg" alt="" className="wh70" />
                <div className="pb-2 font-weight-bolder">
                  For DP related grievances
                </div>
                <div className="size13 greenElement text-center">
                  <a href="mailto:dp@ourbroking.com">dp@ourbroking.com</a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4 px-2">
                <img src="./img/grievence_3.svg" alt="" className="wh70" />
                <div className="pb-2 font-weight-bolder">
                  For Compliance related grievances
                </div>
                <div className="size13 greenElement text-center">
                  <a href="mailto:compliance@ourbroking.com">
                    compliance@ourbroking.com
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3 mb-4 px-2">
                <img src="./img/grievence_4.svg" alt="" className="wh70" />
                <div className="pb-2 font-weight-bolder">
                  For any other grievances
                </div>
                <div className="size13 greenElement text-center">
                  <a href="mailto:ig@ourbroking.com ">ig@ourbroking.com </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <h5 id="basic">Basic details</h5>
        <div className="table-container">
          <table>
            <thead />
            <thead>
              <tr>
                <th className="text-12 text-grey">Name</th>
                <th className="text-12 text-grey">Registration Number</th>
                <th className="text-12 text-grey">Registered Address</th>
                <th className="text-12 text-grey">Contact No.</th>
                <th className="text-12 text-grey">Email id</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-12 text-grey">OUR BROKING SERVICES LLP</td>
                <td className="text-12 text-grey">INZ000309758</td>
                <td className="text-12 text-grey">
                  Room No - 561, LSE Building , Feroze Gandhi Market , Ludhiana
                  -141001
                </td>
                <td className="text-12 text-grey">0161-4072791</td>
                <td className="text-12 text-grey">wecare@ourbroking.com</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <h5>Escalation matrix</h5>
        <p>
          <span className="text-grey">OUR BROKING SERVICES LLP</span>
        </p>
        <div className="table-container">
          <table>
            <thead />
            <thead>
              <tr>
                <th className="text-12 text-grey">Details of</th>
                <th className="text-12 text-grey">Name</th>
                <th className="text-12 text-grey">Address</th>
                <th className="text-12 text-grey">Contact No.</th>
                <th className="text-12 text-grey">E-mail ID</th>
                <th className="text-12 text-grey">Working hours</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-12 text-grey">Customer care</td>
                <td className="text-12 text-grey">Ashish Jain</td>
                <td className="text-12 text-grey">
                  Room No - 561, LSE Building , Feroze Gandhi Market , Ludhiana
                  -141001
                </td>
                <td className="text-12 text-grey">9478817827</td>
                <td className="text-12 text-grey">wecare@ourbroking.com</td>
                <td className="text-12 text-grey">10AM - 5PM</td>
              </tr>
              <tr>
                <td class="text-12 text-grey">Kyc Senior Assistant</td>
                <td class="text-12 text-grey">Shubham</td>
                <td class="text-12 text-grey"> Room No - 561, LSE Building , Feroze Gandhi Market , Ludhiana
                  -141001</td>
                <td class="text-12 text-grey">0161-4072791</td>
                <td class="text-12 text-grey">kyc@ourbroking.com</td>
                <td class="text-12 text-grey">10AM - 5PM
                </td>
              </tr>
              <tr>
                <td className="text-12 text-grey">Head of Compliance</td>
                <td className="text-12 text-grey">Sudhir Kumar</td>
                <td className="text-12 text-grey">
                  Room No - 561, LSE Building , Feroze Gandhi Market , Ludhiana
                  -141001
                </td>
                <td className="text-12 text-grey">0161-4072791</td>
                <td className="text-12 text-grey">compliance@ourbroking.com</td>
                <td className="text-12 text-grey">10AM - 5PM</td>
              </tr>
              <tr>
                <td className="text-12 text-grey">CEO</td>
                <td className="text-12 text-grey">Sudhir Kumar</td>
                <td className="text-12 text-grey">
                  Room No - 561, LSE Building , Feroze Gandhi Market , Ludhiana
                  -141001
                </td>
                <td className="text-12 text-grey">0161-4072791</td>
                <td className="text-12 text-grey">md@ourbroking.com</td>
                <td className="text-12 text-grey">10AM - 5PM</td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
        <p className="text-grey text-12">
          In the absence of a response/complaint not addressed to your
          satisfaction, you may lodge a complaint with:
        </p>
        <ul className="list-items text-grey text-12">
          <li className="text-grey text-12">
            <a
              href="https://scores.gov.in/scores/Welcome.html"
              target="_blank" rel="noreferrer"
            >
              SEBI
            </a>
          </li>
          <li>
            <a
              href="https://investorhelpline.nseindia.com/NICEPLUS/"
              target="_blank" rel="noreferrer"
            >
              NSE
            </a>
          </li>
          <li className="text-grey text-12">
            <a
              href="https://bsecrs.bseindia.com/ecomplaint/frmInvestorHome.aspx"
              target="_blank" rel="noreferrer"
            >
              BSE
            </a>
          </li>
          <li>
            <a
              href="https://www.cdslindia.com/Footer/grievances.aspx"
              target="_blank" rel="noreferrer"
            >
              CDSL
            </a>
          </li>
        </ul>
        <p className="text-grey text-12">
          Note: Please quote your Service Ticket/Complaint Ref No. while raising
          your complaint at SEBI SCORES/Exchange portal.
          <br />
          Refer to{" "}
          <a
            href="https://www.sebi.gov.in/web/?file=https://www.sebi.gov.in/sebi_data/attachdocs/aug-2023/1690893251844.pdf#page=1&zoom=page-width,-16,714"
            target="_blank" rel="noreferrer"
          >
            SEBI Circulars dt 31.07.23{" "}
          </a>{" "}
          regarding Online Resolution of Disputes in the Indian Securities
          Market,an investor/client shall first take up his/her/their grievance
          with the Market Participant by lodging a complaint directly with the
          concerned Market Participant. If the grievance is not redressed
          satisfactorily, the investor/client may,in accordance with the SCORES
          guidelines, escalate the same through the SCORES Portal in accordance
          with the process laid out therein. After exhausting these options for
          resolution of the grievance, if the investor/client is still not
          satisfied with the outcome, he/she/they can initiate dispute resolution
          through the ODR Portal click here to ODR portal and SEBI Circulars to
          know more.
        </p>
        <br />
        <h5 id="individual">Key management personnel details</h5>
        <p>
          <span className="text-grey">OUR BROKING SERVICES LLP</span>
        </p>
        <div className="table-container">
          <table>
            <thead />
            <thead>
              <tr>
                <th className="text-12 text-grey">Name Of The Individual</th>
                <th className="text-12 text-grey">Designation</th>
                <th className="text-12 text-grey">Mobile number</th>
                <th className="text-12 text-grey">E-mail</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-12 text-grey">Sudhir Kumar</td>
                <td className="text-12 text-grey">Managing Director</td>
                <td className="text-12 text-grey">98140-98322</td>
                <td className="text-12 text-grey">md@ourbroking.com</td>
              </tr>
            </tbody>
          </table>
          <br />
        </div>
      </section>
    </main>
    {/* scripts */}
    {/* Page specific scripts */}
  </section>
  );
}

export default Contact;